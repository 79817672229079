import { remove } from '@slideslive/fuse-kit/utils';
import ApplicationController from 'modules/application_controller';

export default class extends ApplicationController {
  loadAllPresentations({ target }) {
    remove(target);
    this.dispatch('loadAll', { target: window, detail: { feed: 'presentations' } });
  }

  loadAllFolders({ target }) {
    remove(target);
    this.dispatch('loadAll', { target: window, detail: { feed: 'folders' } });
  }
}
