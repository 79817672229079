import { defer, pushDataLayer } from '@slideslive/fuse-kit/utils';
import ApplicationController from 'modules/application_controller';

export default class extends ApplicationController {
  connect() {
    pushDataLayer({ sl_user_id: this.userId });

    defer(() => this.dispatch('initialize'));
  }

  get userId() {
    return this.element.dataset.userId;
  }
}
