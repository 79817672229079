import ChoicesAutocompleteController from '@/fuse/javascript/choices_autocomplete_controller';
import { addClasses, createElementFromHTML } from '@slideslive/fuse-kit/utils';

export default class extends ChoicesAutocompleteController {
  initialize() {
    if (this.element.firstElementChild && this.element.firstElementChild.value === '') {
      this.element.firstElementChild.disabled = true;
      this.element.firstElementChild.setAttribute('placeholder', true);
    }

    super.initialize();
  }

  destroyChoices() {
    super.destroyChoices();

    if (this.element.firstElementChild && this.element.firstElementChild.value === '') {
      this.element.firstElementChild.disabled = false;
      this.element.firstElementChild.removeAttribute('placeholder');
    }
  }

  optionCustomPropertiesFor(data) {
    return { description: data.description };
  }

  additionalOptionsForQuery(query) {
    if (!query) {
      return null;
    }

    return [
      {
        label: query,
        value: '0',
        customProperties: {
          labelTemplate: 'Create new speaker — <span class="tw-text-bold">%label%</span>',
        },
      },
    ];
  }

  renderChoice({ classNames: globalClasses }, data, itemSelectText) {
    const role = data.groupId > 0 ? 'role="treeitem"' : 'role="option"';
    const localClasses = addClasses(globalClasses.item, globalClasses.itemChoice, {
      [globalClasses.itemDisabled]: data.disabled,
      [globalClasses.itemSelectable]: !data.disabled,
      [globalClasses.placeholder]: data.placeholder,
    }).join(' ');

    let html;

    if (data.customProperties && data.customProperties.labelTemplate) {
      html = data.customProperties.labelTemplate.replace(/%label%/g, data.label);
    } else {
      html = data.label;
      html += `<div class="tw-text-white/68 tw-text-sm">ID: ${data.value}</div>`;
    }

    return createElementFromHTML(`
      <div
        class="${localClasses}"
        data-select-text="${itemSelectText}"
        data-choice
        data-id="${data.id}"
        data-value="${data.value}"
        ${data.disabled ? 'data-choice-disabled aria-disabled="true"' : 'data-choice-selectable'}
        id="${data.elementId}"
        ${role}
        >
        ${html}
      </div>
    `);
  }

  get showSelectedValuesInDropdown() {
    return true;
  }

  get createTemplates() {
    return {
      choice: this.renderChoice.bind(this),
    };
  }

  get labelField() {
    return 'name';
  }

  get valueField() {
    return 'id';
  }
}
