import { pushDataLayer } from '@slideslive/fuse-kit/utils';
import ApplicationController from 'modules/application_controller';

export default class extends ApplicationController {
  initialize() {
    this.sendGoogleAnalyticEvent();
  }

  sendGoogleAnalyticEvent() {
    const event = this.element.dataset.sendGaEvent;
    if (!event) return;

    pushDataLayer({ event });
  }
}
