import { exportCsv, remove, replaceWith } from '@slideslive/fuse-kit/utils';
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import ApplicationController from 'modules/application_controller';
import moment from 'moment-timezone';

export default class extends ApplicationController {
  static get targets() {
    return ['list', 'commentSent', 'commentTemplate', 'email', 'name', 'message'];
  }

  initialize() {
    this.props = {
      exportData: {},
      firebaseApp: null,
      messagesRef: null,
      commentSentTimeout: null,
    };
  }

  connect() {
    if (this.isTurboPreview) {
      return;
    }

    this.initFirebase();
  }

  initFirebase() {
    this.firebaseApp = firebase.initializeApp(this.firebaseConfig);
    this.messagesRef = this.firebaseApp.database().ref(`/${this.presentationId}`);

    this.messagesRef.on('child_added', (snapshot) => {
      const message = snapshot.val();

      if (this.hasListTarget && !message.deletedAt) {
        const html = this.renderMessageHtml(snapshot.key, message);

        this.listTarget.insertAdjacentHTML('afterbegin', html);
      }

      if (this.canExport) {
        this.exportData[snapshot.key] = this.messageDataToObjectForExport(message);
      }
    });

    this.messagesRef.on('child_removed', (snapshot) => {
      if (this.hasListTarget) {
        const message = this.listTarget.querySelector(`[data-id="${snapshot.key}"]`);

        remove(message);
      }

      if (this.canExport) {
        delete this.exportData[snapshot.key];
      }
    });

    this.messagesRef.on('child_changed', (snapshot) => {
      const message = snapshot.val();

      if (this.hasListTarget) {
        const messageElement = this.listTarget.querySelector(`[data-id="${snapshot.key}"]`);

        if (message.deletedAt) {
          remove(messageElement);
        } else {
          const html = this.renderMessageHtml(snapshot.key, message);
          replaceWith(messageElement, html);
        }
      }

      if (this.canExport) {
        this.exportData[snapshot.key] = this.messageDataToObjectForExport(message);
      }
    });
  }

  renderMessageHtml(key, data) {
    const date = moment(data.createdAt).format('LLL');
    const html = this.commentTemplateTarget.innerHTML;
    const initials = data.name
      .split(' ')
      .slice(0, 2)
      .map((s) => s[0])
      .join('');

    return html
      .replaceAll('{KEY}', key)
      .replaceAll('{NAME}', data.name)
      .replaceAll('{INITIALS}', initials)
      .replaceAll('{MESSAGE}', data.text)
      .replaceAll('{CREATED_AT}', date);
  }

  deleteComment(event) {
    event.preventDefault();

    const messageListItem = event.target.closest('[data-id]');
    const messageKey = messageListItem.dataset.id;
    const message = this.messagesRef.child(messageKey);

    message.update({ deletedAt: new Date().toISOString() });
  }

  sendComment(event) {
    event.preventDefault();

    this.messagesRef.push(this.createMessageObject());
    this.resetMessage();
    this.showCommentSent();
  }

  createMessageObject() {
    const now = new Date().toISOString();

    return {
      createdAt: now,
      updatedAt: now,
      name: this.name,
      email: this.email,
      // registrationId: this.registrationId,
      analyticsUserUuid: this.analyticsUserUuid,
      text: this.messageText,
    };
  }

  resetMessage() {
    this.messageText = '';
    this.messageTarget.focus();
  }

  showCommentSent() {
    if (!this.hasCommentSentTarget) {
      return;
    }

    if (this.commentSentTimeout) {
      clearTimeout(this.commentSentTimeout);
      this.commentSentTimeout = null;
    }

    this.commentSentTarget.hidden = false;

    this.commentSentTimeout = setTimeout(() => {
      this.commentSentTarget.hidden = true;
    }, 3000);
  }

  exportCsv(event) {
    event.preventDefault();

    if (!this.canExport) {
      return;
    }

    const rows = [['Analytics user UUID', 'Registration ID', 'E-mail', 'Name', 'Message', 'Created at', 'Deleted at']];
    const messages = Object.values(this.exportData).sort((a, b) => a.createdAt.localeCompare(b.createdAt));

    for (const data of messages) {
      rows.push([
        data.analyticsUserUuid,
        data.registrationId || '',
        data.email,
        data.name,
        data.text,
        data.createdAt,
        data.deletedAt,
      ]);
    }

    exportCsv(`qa-${this.presentationId}.csv`, rows);
  }

  messageDataToObjectForExport(data) {
    return {
      analyticsUserUuid: data.analyticsUserUuid,
      registrationId: data.registrationId,
      email: data.email,
      name: data.name,
      text: data.text,
      createdAt: data.createdAt,
      deletedAt: data.deletedAt,
    };
  }

  get exportData() {
    return this.props.exportData;
  }

  set exportData(value) {
    this.props.exportData = value;
  }

  get firebaseApp() {
    return this.props.firebaseApp;
  }

  set firebaseApp(value) {
    this.props.firebaseApp = value;
  }

  get messagesRef() {
    return this.props.messagesRef;
  }

  set messagesRef(value) {
    this.props.messagesRef = value;
  }

  get commentSentTimeout() {
    return this.props.commentSentTimeout;
  }

  set commentSentTimeout(value) {
    this.props.commentSentTimeout = value;
  }

  get name() {
    return this.nameTarget.value;
  }

  get email() {
    return this.emailTarget.value;
  }

  get messageText() {
    return this.messageTarget.value;
  }

  set messageText(value) {
    this.messageTarget.value = value;
  }

  get firebaseConfig() {
    return {
      apiKey: 'AIzaSyBiWgqO41yQUTFK3WirLgYJH-lrZLMtmCA',
      databaseURL: 'https://slideslive-qa-default-rtdb.firebaseio.com/',
    };
  }

  get presentationId() {
    return this.element.dataset.presentationId;
  }

  get analyticsUserUuid() {
    return gon.analytics_uuid;
  }

  get canExport() {
    return this.element.dataset.export === 'true';
  }

  get canDeleteMessages() {
    return this.element.dataset.deleteMessages === 'true';
  }
}
