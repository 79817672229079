import { defer } from '@slideslive/fuse-kit/utils';
import ApplicationController from 'modules/application_controller';
import Sortable from 'sortablejs';

export default class extends ApplicationController {
  initialize() {
    this.props = {
      sortable: null,
    };

    defer(this.initSortable.bind(this));
  }

  initSortable() {
    if (!this.isSortable) {
      return;
    }

    this.sortable = new Sortable(this.element, {
      animation: 150,
      group: 'super-acc',
      sort: true,
      dataIdAttr: 'data-item-id',
      draggable: '[data-sortable-item="true"]',
      handle: '[data-sortable-handle="true"]',
      onSort: () => {
        const data = {};

        for (let i = 0; i < this.element.children.length; i++) {
          const item = this.element.children[i];
          const id = item.dataset.itemId;

          data[id] = i;
        }

        fetch(this.sortableUrl, {
          method: 'PATCH',
          headers: {
            'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
            'Content-Type': 'application/json; charset=utf-8',
          },
          body: JSON.stringify(data),
        }).catch((res) => console.warn(res));
      },
    });
  }

  get sortableUrl() {
    return this.element.dataset.sortableUrl;
  }

  get isSortable() {
    return this.element.dataset.sortable === 'true';
  }

  get sortable() {
    return this.props.sortable;
  }

  set sortable(value) {
    this.props.sortable = value;
  }
}
