import { pushDataLayer } from '@slideslive/fuse-kit/utils';
import ApplicationController from 'modules/application_controller';

export default class extends ApplicationController {
  static get targets() {
    return ['turboFrameSearchResult', 'infiniteScrollSearchResult'];
  }

  initialize() {
    this.sendGoogleAnalyticSearchEvent();
  }

  dispatchTypeChangedEvent(event) {
    event.stopPropagation();

    const searchType = this.searchTypeFromTabChangedEvent(event);
    this.dispatch('typeChanged', {
      target: document,
      prefix: 'search',
      detail: { type: searchType },
    });
  }

  showSearchLoading() {
    for (const element of this.turboFrameSearchResultTargets) {
      element.innerHTML = '';
    }

    for (const element of this.infiniteScrollSearchResultTargets) {
      const controller = this.findControllerOnElement(element);

      controller.reset();
      controller.showSkeleton();
    }
  }

  sendGoogleAnalyticSearchEvent(event) {
    const searchEvent = this.element.dataset.searchGaEvent || 'sl_search';
    const searchQuery = this.element.dataset.searchQuery;
    const searchType = this.searchTypeFromTabChangedEvent(event);

    pushDataLayer({
      event: searchEvent,
      search_type: searchType,
      search_query: searchQuery,
    });
  }

  searchTypeFromTabChangedEvent(event) {
    if (!event) return this.element.dataset.initialSearchType;

    return event.detail.relatedTarget.dataset.tabName;
  }
}
