import ApplicationController from 'modules/application_controller';

export default class extends ApplicationController {
  static get targets() {
    return [
      'recordingRadiusUserInput',
      'searchInput',
      'map',
      'recordingRadius',
      'recordingRadiusLat',
      'recordingRadiusLng',
    ];
  }

  connect() {
    this.initializeMap();
  }

  initializeMap() {
    if (!window.isMapApiInitialized(this.initializeMap.bind(this))) {
      return;
    }

    const defaultLatLng = {
      lat: this.lat,
      lng: this.lng,
    };

    const mapOptions = {
      mapId: 'recordingRadius',
      mapTypeId: 'roadmap',
      center: defaultLatLng,
      zoom: 5,
      mapTypeControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false,
    };

    this.map = new google.maps.Map(this.mapTarget, mapOptions);

    const markerOptions = {
      position: defaultLatLng,
      map: this.map,
      gmpDraggable: !this.readonly,
    };

    this.marker = new google.maps.marker.AdvancedMarkerElement(markerOptions);

    const circleOptions = {
      strokeColor: '#FF0000',
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: '#FF0000',
      fillOpacity: 0.35,
      map: this.marker.map,
      center: this.marker.position,
      editable: !this.readonly,
      radius: this.radius,
    };

    this.circle = new google.maps.Circle(circleOptions);

    if (!this.readonly) {
      this.marker.addListener('drag', (event) => this.circle.setCenter(event.latLng));
      this.marker.addListener('dragend', (event) => this.setPositionFromMap(event.latLng));

      this.circle.addListener('radius_changed', () => this.setRadiusFromMap(Math.round(this.circle.getRadius())));

      this.searchBox = new google.maps.places.Autocomplete(this.searchInputTarget, { fields: ['geometry'] });

      this.searchBox.bindTo('bounds', this.map);
      this.searchBox.addListener('place_changed', () => {
        const place = this.searchBox.getPlace();
        if (place?.geometry) {
          this.map.setCenter(place.geometry.location);
          this.marker.position = place.geometry.location;
          this.circle.setCenter(place.geometry.location);

          this.setPositionFromMap(place.geometry.location);
        }
      });
    }
  }

  setPositionFromMap(position) {
    this.lat = position.lat();
    this.lng = position.lng();
  }

  setRadiusFromMap(radius) {
    this.recordingRadiusTarget.value = radius;
    this.recordingRadiusUserInputTarget.value = Math.round(radius / 1000);
  }

  setRecordingRadiusFromUserInput(event) {
    const radius = parseFloat(event.target.value) * 1000;

    this.circle.setRadius(radius);
    this.recordingRadiusTarget.value = radius;
    this.recordingRadiusUserInputTarget.value = Math.round(radius / 1000);
  }

  get readonly() {
    return this.element.dataset.readonly === 'true';
  }

  set lat(value) {
    this.recordingRadiusLatTarget.value = value;
  }

  set lng(value) {
    this.recordingRadiusLngTarget.value = value;
  }

  get lat() {
    return parseFloat(this.recordingRadiusLatTarget.value);
  }

  get lng() {
    return parseFloat(this.recordingRadiusLngTarget.value);
  }

  get radius() {
    return parseInt(this.recordingRadiusTarget.value, 10);
  }
}
