import { getData, setData } from '@slideslive/fuse-kit/utils';
import ApplicationController from 'modules/application_controller';

export default class extends ApplicationController {
  static get targets() {
    return ['content'];
  }

  initialize() {
    this.props = {
      hidden: false,
    };
  }

  handleRender() {
    if (this.isTurboPreview) {
      this.handleRenderPreview();

      return;
    }

    this.handleRenderNew();
  }

  handleRenderPreview() {
    if (!this.element.hidden) {
      return;
    }

    setData(window, this.windowCachedDataKey, { hidden: true });
  }

  handleRenderNew() {
    const cachedData = getData(window, this.windowCachedDataKey);

    if (!cachedData) {
      return;
    }

    setData(window, this.windowCachedDataKey, undefined);
    this.element.hidden = cachedData.hidden;
  }

  contentRendered() {
    if (this.hidden) {
      return;
    }

    this.element.hidden = false;
  }

  hide() {
    this.hidden = true;
    this.element.hidden = true;
  }

  get hidden() {
    return this.props.hidden;
  }

  set hidden(value) {
    this.props.hidden = value;
  }

  get windowCachedDataKey() {
    return `fireEmptyCache:${window.location.pathname}#${this.contentTarget.id}`;
  }
}
